<template>
  <div
    class="task-detail-right"
  >
    <div class="card">
      <div class="card-header">
        <h5>{{ $t('emailPreferencesTitle') }}</h5>
      </div>
      <div class="card-block task-details">
        <preferences-form
          :license="license"
          :formdata="formdata"
          :is-touched-data="isTouchedData"
          :is-disabled="isDisabled"
          :error-message="errorMessage"
          model="emailPreferencesOptions"
          @update="checkTouched($event)"
          @submit="submit"
        ></preferences-form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { useEmailPreferencesUpdating } from '@/application/composables/emailPreferencesUpdating.js'

export default {
  name: 'LicenseDetailPreferences',
  components: {
    'preferences-form': defineAsyncComponent(() => import('@/application/components/forms/PreferencesForm.vue')),
  },
  props: {
    license: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      formdata,
      checkTouched,
      isTouchedData,
      isDisabled,
      submit,
      errorMessage,
    } = useEmailPreferencesUpdating(props.license)
    return {
      formdata,
      checkTouched,
      isTouchedData,
      isDisabled,
      submit,
      errorMessage,
    }
  },
}
</script>
