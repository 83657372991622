import { ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { updateLicenseField } from '@/application/services/dashboard.js'
import { useErrorHandler } from '@/application/composables/responseErrorHandler.js'
import { TouchedData } from '@/application/utils/touchedData.js'
import { useModal } from '@/application/composables/modal.js'

export function useSubmit(formdata, license, mutator) {
  const store = useStore()
  const i18n = useI18n()
  const touchedData = ref({})
  const isTouchedData = ref(false)
  const isDisabled = ref(false)
  const errorMessage = ref('')
  const { openModal } = useModal()
  const { responseErrorHandler } = useErrorHandler()
  touchedData.value = new TouchedData(JSON.parse(JSON.stringify(formdata)))
  const checkTouched = (formdata) => {
    isTouchedData.value = touchedData.value.isChanged(formdata)
  }
  const submit = (data) => {
    isDisabled.value = true
    updateLicenseField(data)
      .then(() => {
        touchedData.value = new TouchedData(JSON.parse(JSON.stringify(data)))
        checkTouched(data)
        const mutatedData = {
          license_key: license.license_key,
          data: {
            ...data,
          },
        }
        store.commit(mutator, mutatedData)
        isDisabled.value = false
        openModal(i18n.t('success'), i18n.t('successSaving'))
      })
      .catch(err => {
        const status = err.response.status
        errorMessage.value = responseErrorHandler(status)
        isDisabled.value = false
        openModal(i18n.t('error'), i18n.t('errorSaving'))
      })
  }
  return {
    submit,
    checkTouched,
    isTouchedData,
    isDisabled,
    errorMessage,
  }
}
