import { ref } from 'vue'
import { useSubmit } from '@/application/composables/submit.js'

export function useEmailPreferencesUpdating(license) {
  const {
    email_preferences: {
      whats_new,
      best_practices,
    },
  } = license
  const formdata = ref({
    whats_new,
    best_practices,
  })
  const {
    submit,
    checkTouched,
    isTouchedData,
    isDisabled,
    errorMessage,
  } = useSubmit(formdata.value, license, 'changeEmailPreferencesState')
  return {
    submit: (data) => submit({ email_preferences: data }),
    formdata,
    checkTouched,
    isTouchedData,
    isDisabled,
    errorMessage,
  }
}
